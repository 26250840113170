<template>
  <div class="gestion-societe-a-traiter">
    <div class="permission-hearder">
      <div class="title"></div>
      <div
        v-if="getLoadingSocieteAtraiter"
        class="chargement-message-text ml-2"
      >
        Chargement en cours
      </div>
      <div class="fix-height-slide">
        <div
          v-for="gestion in getGestionSocieteAtraiter"
          :key="gestion.id"
          class="selected-role"
        >
          <div id="info-wrap" class="interface-permission">
            <div
              class="description-column"
              @click.prevent="handleClickGestion(gestion)"
            >
              <span>
                {{ gestion.name }}
              </span>
            </div>
            <div class="info-column">
              <div
                class="icon-color"
                v-if="
                  selectedGestionSociete &&
                    selectedGestionSociete.id == gestion.id
                "
              >
                <font-awesome-icon icon="check" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="permission-hearder" v-if="selectedGestionSociete">
      <div class="permission-user">
        <div class="title">Sociétes</div>
        <div class="Search-style-component">
          <div class="search-box">
            <font-awesome-icon icon="search" class="icon" />
            <input
              class="search-input"
              placeholder="Chercher"
              v-model="search"
            />
          </div>
        </div>
        <div class="fix-height-slide" v-if="selectedGestionSociete">
          <div
            v-for="societe in computedListSearch(
              selectedGestionSociete.societe
            )"
            :key="societe.id"
          >
            <div id="info-wrap" class="">
              <div>
                <div class="info-column">
                  <b-form-checkbox
                    name="flavour-1"
                    :id="'checkbox-group-1' + societe.id"
                    class="check-th"
                    v-model="societe.checked"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="description-column">
                <span>
                  {{ societe.filiale }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <b-button
              type="submit"
              class="button-valide-style mt-1 ml-3"
              @click.prevent.stop="updateFestionSocieteTraiter"
            >
              <span>
                Enregistrer
                <div
                  class="spinner-border"
                  role="status"
                  v-if="getLoadingAddSocieteAtraiter"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="permission-hearder" v-if="selectedGestionSociete">
      <div class="permission-user">
        <div class="title">Interfaces</div>
        <div class="fix-height-slide-inetrafece">
          <div
            v-for="inter in selectedGestionSociete.interface"
            :key="inter.key"
          >
            <div id="info-wrap" class="">
              <div>
                <div class="info-column">
                  <b-form-checkbox
                    name="flavour-1"
                    :id="'checkbox-group-1' + inter.key"
                    class="check-th"
                    v-model="inter.checked"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="description-column">
                <span>
                  {{ inter.interface }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <b-button
              type="submit"
              class="button-valide-style mt-1 ml-3"
              @click.prevent.stop="updateFestionSocieteTraiter"
            >
              <span>
                Enregistrer
                <div
                  class="spinner-border"
                  role="status"
                  v-if="getLoadingAddSocieteAtraiter"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      loadingSociete: false,
      loadingInterface: false,
      selectedGestionSociete: null,
      search: null
    };
  },
  methods: {
    ...mapActions(['getAllSocieteAtraiters', 'updateSocieteAtraiter']),
    handleClickGestion(societe) {
      this.selectedGestionSociete = societe;
    },
    async updateFestionSocieteTraiter() {
      const response = await this.updateSocieteAtraiter({
        data: this.selectedGestionSociete
      });
    }
  },
  computed: {
    ...mapGetters([
      'getGestionSocieteAtraiter',
      'getLoadingSocieteAtraiter',
      'checkPermission',
      'getLoadingAddSocieteAtraiter'
    ]),
    computedListSearch() {
      return function(data) {
        if (data) {
          let table = data;
          if (this.search != null) {
            table = table.filter(item =>
              item.filiale.toLowerCase().includes(this.search.toLowerCase())
            );
          }
          return table;
        }
      };
    },
    computedChecked() {
      return function(filters) {
        let tableCheck = filters.filter(item => item.checked == true);
        if (tableCheck.length == filters.length) {
          return true;
        }
        return false;
      };
    }
  },
  mounted() {
    this.getAllSocieteAtraiters();
  }
};
</script>

<style lang="scss" scoped>
.gestion-societe-a-traiter {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5c7;
  padding: 10px;
  border-radius: 15px;

  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .custom-list-group {
    overflow-y: auto;
    max-height: calc(100vh - 330px);
    height: calc(100vh - 330px);
  }
  .permission-hearder {
    height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 15px;
    width: 34%;
    max-width: 34%;
    margin-right: 10px;
  }

  .permission-hearder-autorisations {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
    width: 50%;
    max-width: 50%;
    margin-right: 10px;
  }
  .body-permission {
    height: 100%;
    padding: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 15px;
    width: 27%;
  }
  .permission-body {
    width: 75%;
    display: flex;

    .permission-select-component {
      margin-bottom: 10px;
    }
    .permission-user,
    .permission-interface {
      width: 37%;
      padding: 5px;
    }
    .permission-autorisation {
      width: 25%;
      padding: 5px;
    }
  }
  .position-btn {
    position: relative;
    text-align-last: center;
    padding-top: 6px;
    .config-coef-btn {
      border-radius: 5px;
    }
  }
}
.Search-style-component {
  .search-box {
    position: relative;
    top: 0;
    right: 0px;
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;
    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .search-input {
      height: 18px;
      width: 100%;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: black;
      font-size: 13px;

      &:focus,
      &:active {
        opacity: 1;
      }
    }
  }
}
</style>
<style lang="scss">
.gestion-societe-a-traiter {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  #info-wrap {
    overflow: hidden;
  }

  .description-column {
    width: 72%;
    float: left;
    padding-left: 4px;
    font-size: 11px;
  }
  .info-column {
    width: 11%;
    float: left;
  }
  .interface-permission {
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;
  }
  .autorisation-permission {
    padding: 3px;
    padding-left: 5px;
    margin-bottom: 9px;
    font-size: medium;
  }

  .permission-hearder::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .permission-hearder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .permission-hearder::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }

  .permission-hearder::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .permission-hearder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .permission-hearder::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }

  .permission-hearder-autorisations {
    display: block;
    overflow-y: scroll !important;
    height: 10px;
    // height: calc(100vh - 50px);
  }
  .permission-hearder-autorisations::-webkit-scrollbar {
    width: 8px;
    // height: 100px;
  }

  /* Track */
  .permission-hearder-autorisations::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder-autorisations::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .permission-hearder-autorisations::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }

  .icon-color {
    color: #4d4bac;
  }
  .selected-role {
    cursor: pointer;
  }
  .check-permission {
    accent-color: #4d4bac;
    height: 20px;
    width: 20px;
  }

  .fix-height-slide {
    display: block;
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: calc(100vh - 341px);
    min-height: calc(100vh - 341px);
    padding-right: 9px;
    margin-bottom: 2px;
  }

  .fix-height-slide-inetrafece {
    display: block;
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: calc(100vh - 300px);
    min-height: calc(100vh - 300px);
    padding-right: 9px;
    // margin-bottom: 5px;
  }
  .fix-height-slide::-webkit-scrollbar,
  .fix-height-slide-inetrafece::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .fix-height-slide::-webkit-scrollbar-track,
  .fix-height-slide-inetrafece::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .fix-height-slide::-webkit-scrollbar-thumb,
  .fix-height-slide-inetrafece::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .fix-height-slide::-webkit-scrollbar-thumb:hover,
  .fix-height-slide-inetrafece::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
}
</style>
